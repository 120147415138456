import './components/jquery.responsifyBgImg';
import './vendor/owl.carousel.min';
import './vendor/jquery.sticky';
import './vendor/svgforeverybody';
import Rellax from './vendor/rellax.min';

(function($) {

    const $body = $('body');
	  // Offcanvas

    $('.js-toggle-nav').on('click', () => {
      $body.toggleClass('menu-open');
    });

    // Owl Carousel

    $('.owl-reviews-carousel').owlCarousel({
        loop:true,
        margin:25,
        nav:true,
        dots: true,
        autoplay: true,
        smartSpeed: 750,
        autoplayHoverPause: true,
        items:1
    });

    $('.owl-slider-carousel').owlCarousel({
        loop:true,
        margin:25,
        nav:true,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        smartSpeed: 750,
        autoplaySpeed: 1000,
        autoplayTimeout: 7000,
        items:1
    });

    // Scroll to ID

	$('.js-scrollto').on('click', function () {

    	var id = $(this).data('target');
    	scrollTo(id);
	});

	// Footcols

    $('.footcol h3').click(function() {
        $(this).next('.footcol__content').toggleClass('open');
    });

    // Parallax

    var el = $('.rellax');

    if (el.length > 0) {
        var rellax = new Rellax('.rellax');
    }

    // Lightbox

    $('.js-lightbox').click(function(e) {
        e.preventDefault();
        $('.lightbox').addClass('lightbox--active');
    });

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

    // Sticky JS

    var winw = $(window).width();
    
    $('#masthead').on('addsticky', function() {
            
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            $(this).addClass('sticky');
        } else {
            $(this).removeClass('sticky');
        }
    });

    var scrollTimer;

    $(window).on('scroll', function(e) {
        if (scrollTimer) { clearTimeout(scrollTimer); }
            scrollTimer = setTimeout(function() {
           
            $('#masthead').trigger('addsticky');
            $('.btt').trigger('addbtt');
        }, 100);
    });

    // Back To Top

    $('.btt').on('addbtt', function() {
            
        var scroll = $(window).scrollTop();
        if (scroll >= 500) {
            $(this).addClass('btt--is-visible');
        } else {
            $(this).removeClass('btt--is-visible');
        }
    });



    // Scroll Nag

    var scrolled = false;
    var nagvisible = false;
    
    $(window).scroll( function() {
        scrolled = true;
    
        if (nagvisible == true) {
            setTimeout( function(){
                $('.scrollnag').fadeOut(500);
                nagvisible == false;
            }, 1000)
        }
    });
    
    setTimeout( function(){
    
        if (scrolled == false) {
            $('.scrollnag').fadeIn(500);
            nagvisible = true;
        }
    }, 4000 );

})(jQuery);